import React from "react"
// Customizable Area Start
import {
    styled
} from "@material-ui/core";

import { withTranslation } from "react-i18next";
import MaterialGradeListController from "./MaterialGradeListController.web";
//@ts-ignore
import Graph from "react-graph-vis"

// Customizable Area End

class MaterialGradeScreen extends MaterialGradeListController {
    // Customizable Area Start
    options = {
        autoResize: true,
       
        nodes: {
          font: {
            multi: true,
            face: 'Quicksand',
            shapeProperties: {
              useImageSize: false
            },
            bold: {
              color: "var(--primary-neutrals-warm-gray-600, #666)"
            },
            size: 14,
          },
        },
        physics: {
          solver: "repulsion",
          repulsion: {
            nodeDistance: 100,
            centralGravity: 0.1,
            springConstant: 0.02,
            springLength: 200,
            damping: 0.3,
          }
        },
        interaction: {
          dragNodes: true,
          zoomView: false,
          dragView:false
        },
        edges: {
          arrows: {
            to: {
              enabled: false,
              src: undefined,
              type: "arrow",
              imageHeight: undefined,
              imageWidth: undefined,
              scaleFactor: 1,
            },
          },
          length: 120,
          color: {
            inherit: 'from',
            hover: '#848484',
            color: '#848484',
            opacity: 1.0,
            highlight: '#848484',
          },
        },
      };
    // Customizable Area End
    render(){
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <DrawerStyle>
                   <Graph
                    graph={this.state.graph}
                    options={this.options}
                    events={{ select: this.selectGrade }}
                    style={{ height: "100vh", width: '100%', overflow: 'auto' }}
                    data-testid="graph"
                    />
            </DrawerStyle>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export default withTranslation()(MaterialGradeScreen)
const DrawerStyle = styled('div')(({ theme }) => ({
 

}));
// Customizable Area End