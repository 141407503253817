// Customizable Area Start
import * as React from "react";
import {
    Typography,
    Box,
    Button,
    styled,
} from "@material-ui/core";

import {
    button_back,
    group_checkout,
    skip_star,
    loader
} from "./assets";
import { Link } from 'react-router-dom';
import PremiumCheckoutsController from "./PremiumCheckoutsController.web";
import NavigateLayout from "../../../components/src/NavigateLayout";
import { Props } from "./StripePayments";
import { withTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web'
class PremiumCheckouts extends PremiumCheckoutsController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <NavigateLayout menuName="Settings" isShowBottomMenu={false}>
                <SettingContainer>
                    <Box className="setting-container">
                        <Box className="mainPremiumWrSubWrapper">
                            <Box className="settingCheckBackground">
                                <CenterPortion navigation={undefined} id={""} />
                            </Box>
                            <Box className="contentPreminumContainers">
                                <Box style={{ display: 'flex', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between', }}>
                                    <img src={button_back} className="" alt="back button" style={{ marginRight: '10px' }} onClick={this.props.navigation.goBack} />
                                    <Typography style={webStyle.premiumtextTitle}>
                                        {this.props.t("Premium checkout")}
                                    </Typography>
                                    <Typography style={{ ...webStyle.canceltextTitle, cursor: 'pointer' }} onClick={this.handleClickCancel} data-test-id="cancel-btn">
                                        {this.props.t("Cancel")}
                                    </Typography>
                                </Box>

                                <Box>
                                    <img src={group_checkout} className="group_stateImage" alt="group Setting" />
                                </Box>
                                <Box style={{ textAlign: 'left', width: '100%' }}>
                                    <img src={skip_star} className="group_stateImage" alt="start" style={{ marginRight: '10px' }} />
                                </Box>
                                <Box>
                                    <Typography style={webStyle.summaryText}>
                                        {this.props.t("Find your perfect plan")}
                                    </Typography>
                                </Box>
                                {this.state.loading ? <img src={loader} alt="Loading..." /> : this.state.planList.map((item: any) =>
                                    <Box style={{ ...webStyle.sectionblock, filter: item.id === this.state.selectedPlan.id ? "initial" : "brightness(75%)" }} key={item.id} onClick={() => {
                                        localStorage.setItem('planId', item.id);
                                        localStorage.setItem("amountToPay", item.attributes.price_cents);
                                        this.setState({ selectedPlan: item })
                                    }} id="plan-item">
                                        <Box>
                                            <Typography data-testid="plans" style={webStyle.discoverText}>
                                                {item.attributes.name}
                                            </Typography>
                                            <Typography style={webStyle.additionalText}>
                                                {this.getPriceDetail(item).total}
                                            </Typography>
                                        </Box>
                                        <Box style={webStyle.monthText}>
                                            <Typography style={webStyle.monthTitle} >
                                                {this.getPriceDetail(item).perMonth}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}

                                <Box style={webStyle.subscriptionSection}>
                                    {this.state.selectedPlan && this.state.selectedPlan.attributes &&
                                        <>
                                            <Typography data-testid="selectedplan" style={webStyle.subscriptionTitle} >
                                                {this.getRenewDate()}
                                            </Typography>
                                            <Typography style={webStyle.subscriptionContent} >
                                                {this.props.t("But no worries, you're the boss! You can cancel")} <Typography component='span' style={webStyle.subscriptionContentBold}>{this.props.t("anytime")}</Typography> {this.props.t("in your subscription settings.")}
                                            </Typography>
                                        </>
                                    }
                                    <Typography onClick={this.handleClickTerms} style={{ ...webStyle.subscriptionTerms, cursor: 'pointer' }} data-test-id="terms-btn">
                                        {this.props.t("Terms & Conditions")}
                                    </Typography>
                                </Box>

                                <Box style={{ textAlign: 'right', width: '100%' }}>
                                    <img src={skip_star} className="group_stateImage" alt="start" style={{ marginRight: '10px' }} />
                                </Box>

                                <Box style={webStyle.boxparents}>
                                    <Box style={webStyle.btnBoxMains}>
                                        <Link to="/StripePaymentsMethod" id="continue-btn">
                                            <Button style={webStyle.btnTitles}>
                                                {this.props.t("Continue")}
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </SettingContainer>
            </NavigateLayout>
        );
    }
}
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    "& .MuiButton-label": {
        fontFamily: "Nunito",
        textTransform: 'none',
    },


    '& .mainPremiumWrSubWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
    },

    '& .settingCheckBackground': {
        backgroundColor: '#fff',
    },
    '& .settingImgesWrappercheck': {
        height: '100%',
        display: 'flex',
        paddingBottom: '30px',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
    },

    '& .settingesTitlecheck': {
        fontFamily: 'Nunito',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px',
        fontSize: '22px',
        fontWeight: '900',

    },

    '& .teddyesImage': {
        Width: '245px',
        marginRight: '10px',
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
    },

    '& .group_stateImage': {
        marginTop: '40px'
    },

    '& .contentPreminumContainers': {
        overflow: 'auto',
        padding: '70px 24px 100px',
        display: 'flex',
        flex: '6',
        flexDirection: 'column',
        background: 'linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
        alignItems: 'center',
    },


    [theme.breakpoints.up('md')]: {
        overflowX: 'hidden',

        "& .setting-container": {
            position: 'relative',
        },

        '& .mainPremiumWrSubWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
            minHeight: '100vh',
            backgroundColor: 'rgb(169,94,219)'
        },

        '& .settingCheckBackground': {
            height: '100vh',
            flex: 5,
            position: 'fixed',
            width: 'calc(50% - 120px)'
        },

        '& .contentPreminumContainers': {
            paddingTop: '90px',
            position: 'relative',
            left: "50%",
            maxWidth: "50%"
        },
        '& .settingImgesWrappercheck': {
            paddingTop: 'unset',
            padding: 0,
        },

        '& .teddyesImage': {
            // width: "50%",
            maxWidth: 'unset',
        },

        '& .settingesTitlecheck': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

    },

    [theme.breakpoints.between('md', 'lg')]: {
        "& .teddyesImage": {
            maxWidth: '240px'
        }
    },


    [theme.breakpoints.down('sm')]: {
        '& .settingCheckBackground': {
            display: 'none',
        },
    },
    [theme.breakpoints.up('lg')]: {
        "& .teddyesImage": {
            maxWidth: 'unset'
        },


        '& .mainPremiumWrSubWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },

    },
    [theme.breakpoints.down('xs')]: {
        '& .settingCheckBackground': {
            display: 'none',
        },

    },

}))

const webStyle = {

    summaryText: {
        color: '#FFFFFF',
        fontFamily: 'Nunito',
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
    },
    sectionblock: {
        border: '4px solid transparent',
        borderImageSource: 'linear-gradient(90deg, #69C4FD 0%, #A567DE 100%)',
        borderImageSlice: 1,
        borderWidth: '4px',
        width: '100%',
        marginTop: '45px',
        backgroundColor: '#fff',
        borderRadius: '15px',
        padding: '20px',
        paddingRight: 0,
        display: 'flex',
        justifyContent: 'space-between',
    },
    monthText: {
        padding: '10px',
        width: '160px',
        height: '39px',
        borderRadius: '10px 0px 0px 10px',
        backgroundColor: 'rgb(235, 206, 255)',
    },
    monthTitle: {
        fontFamily: 'Quicksand',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '12px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#A95EDB',
        padding: '1px',
    },


    subscriptionTitle: {
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '29px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#E6E6E6'
    },

    subscriptionSection: {
        marginTop: '60px',
    },


    subscriptionTerms: {
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#62CDFF',
        marginTop: '10px',
    },

    subscriptionContent: {
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#E6E6E6',
        padding: '0px 40px 0px 40px',
    },

    subscriptionContentBold: {
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Quicksand',
        fontSize: '14px',
        textAlign: 'center' as 'center',
        letterSpacing: '0px',
        color: '#E6E6E6',
    },

    discoverText: {
        fontFamily: 'Quicksand',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#4D4D4D',
    },
    additionalText: {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '12px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#7F7F7F',
        marginTop: '8px',
    },
    canceltextTitle: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        fontWeight: 900,
        lineHeight: '12px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#fff'
    },
    premiumtextTitle: {
        fontFamily: 'Nunito',
        fontSize: '22px',
        fontWeight: 900,
        lineHeight: '26px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        width: '200px',
        paddingLeft: '20px'
    },



    premiumText: {
        fontFamily: 'Quicksand',
        fontSize: '22px',
        fontWeight: 700,
        color: '#fff'
    },





    boxparents: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        marginTop: '40px',
    },
    btnTitles: {

        fontWeight: 700,
        fontSize: '16px',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        width: '350px',
        height: '40px',
        lineHeight: '16px',
        letterSpacing: '0.800000011920929px',
        borderRadius: '12px',
        fontFamily: 'Nunito !important',
        maxWidth: '90%'
    },
    btnBoxMains: {
        margin: '15px 0px 0px 0px',
        background: '#36BFFF',
        width: '100%',
        textAlign: 'center' as 'center',
        height: 'auto',
        borderRadius: '12px',
        boxSizing: 'border-box' as 'border-box',
    },




};

export default withTranslation()(PremiumCheckouts)
// Customizable Area End
