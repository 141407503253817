import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { algebra,greyImage,gradeIcon, orangeHappy,ghostlyAvatar, orangeHealthy, orangeWeak, orangeWorried, blueHappy, blueHealthy, blueWeak, blueWorried, lightgreenHappy, lightgreenHealthy, lightgreenWeak, lightgreenWorried, purpleHappy, purpleHealthy, purpleWeak, purpleWorried, redHappy, redHealthy, redWeak, redWorried, brownHappy, brownHealthy, brownWeak, brownWorried, yellowHappy, yellowHealthy, yellowWeak, yellowWorried, greenHappy, greenHealthy, greenWeak, greenWorried } from "./assets";
import { Message } from "framework/src/Message";
//@ts-ignore
import { SelectEvent } from 'react-graph-vis';
import { setStorageData } from "framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import { TFunction } from "i18next";

type EventHandler = (event: SelectEvent) => void;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t:TFunction
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    graph: {};
    challengeModal: boolean;
    drawerSide: boolean;
    lessonData: Array<INodeData>
    course_Node: ICourseNode | undefined;
    topic: string | undefined;
    challengeShuffleModal: boolean
    description: string;
    CurrentNode: ICurrentMaterialNode | undefined;
    topicName: string,
    UnitName: string,
    ChallengeError: string,
    ToastMessage: string;
    ToastOpen: boolean;
    ChallengeLesson: IChallengeLesson |undefined
    gradeCountryId:number|undefined
    showCategoryGraph:boolean
    gradeList:{},
    avatarIcon:any, 
    avatarHealth: any,
    avatarColor: any,
    PremiumUser:boolean
    unitId: string,
    storeSubCategory:Object,
    searchItem:ISearchedItems|undefined 
    profileLoading:boolean
    subCategoryLoading:boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start

export interface ISearchedItems{
    data:INodeInfo,
    section:string,
    name:string,
    image:string
  }
export interface INodeData{
    id:string,
    type?:string,
    label?:string,
    image:string,
    font?:number,
    size:number,
    shape:string,
    child?: Array<INodeData> 
  }


function flattenData(data: Array<INodeData>) {
    const flattenedData: { id: string; type: string | undefined; label: string | undefined; size: number; shape: string; image: string; font: number | undefined; }[] = [];

    function flattenNode(item:INodeData ) {
        flattenedData.push({
            id: item.id,
            type: item.type,
            label: item.label,
            size: item.size,
            shape: item.shape,
            image: item.image,
            font: item.font
        });

        if (item.child && Array.isArray(item.child)) {
            item.child.forEach(flattenNode);
        }

    }
    data.forEach(flattenNode);
    return flattenedData;
}

function customWrapLabel(text: string, maxLength: number) {
    const temp = text.replace(/\n/g, ' ')
    const words = temp.split(' ');
    let current_Line = words[0];
    let lines = [];
    for (let wordItem = 1; wordItem < words.length; wordItem++) {
        if (current_Line.length + words[wordItem].length <= maxLength) {
            current_Line += ' ' + words[wordItem];
        } else {
            lines.push(current_Line);
            current_Line = words[wordItem];
        }
    }
    lines.push(current_Line);
    return lines.join('\n');
}


function createEdges(node: INodeData): Array<Object> {
    const edges = [];
    if (node?.child && node?.child.length > 0) {
        for (const childNode of node.child) {
            edges.push({
                from: node.id,
                to: childNode.id
            });
            edges.push(...createEdges(childNode));
        }
    }
    return edges;
}

interface ICurrentMaterialNode {
    label: string;
    id: string | number;
    image: string;
    type: string;
}

interface INode {
    id: string;
    size: number;
    child?: IChildData;
    image: string;
    label: string;
    shape: string;
    type: string;
}

interface ICourseNode {
    label: string;
    shape: string;
    type: string;
    id: string;
    image: string;
    size: number;
}

export interface IChildData{
    data:Array<INodeInfo>
  }
export interface IAttributes{
    id: number,
    name: string,
    description: string,
    content: string,
    updated_at: string,
    image?:string;
    course_id: number,
    created_at: string,
    message?:string,
    category_id: number,
    sub_category_id: number,
    unit_id: number,
    unit_name:string,
    unit_number:string,
    main_id: number,
    course_name?:string,
    unit?:IChildData ,
    courses?:IChildData,
    main?:IChildData ,
    lesson?:IChildData,
    cheatsheet_pdf: string;
    summary_pdf: string;
    summary_video: string;
  }
export interface INodeInfo{
    index: number | undefined;
    id:string,
    type:string,
    attributes:IAttributes
  }

  export interface IGradeResponse{
    data: Array<IGrade>
  }
 export interface IGrade{
    id:string,
    type:string,
    attributes:IGradeAttributes
  }

  export interface ICurrentResponseAttributes{
    avatar_health:string,
    favorite_color:string,
    app_country:{
        id:number
    },
    payment_premium:boolean
  }

  export interface ICurrentProfileResponse{
    data:{
        attributes:ICurrentResponseAttributes
    }
  }
  export interface IGradeAttributes{
    name:string,
    image:string,
    id:number
  }
export interface IChallengeResponse {

    data: {
        id: number,
        type: string,
        attributes: IChallengeLesson,
    }

}
export interface IChallengeLesson {
    id: number,
    name: string,
    description: string,
    content: string,
    course_id: number,
    created_at: string,
    updated_at: string,
    category_id: number,
    sub_category_id: number,
    unit_id: number,
    unit_name:string,
    main_id: number,
    image?:string;
    message?:string
}

export interface ISubCategoryResponse{
    id:string,
    is_default_Grade:boolean,
    category_image_url:string,
    child:IChildData,
    grade:string,
    summary_pdf_url:string,
    summary_video_url:string,
    cheatsheet_pdf_url:string,
  }
export interface IError {
    errors: Array<string>
}

// Customizable Area End

export default class ChallengeController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getSubCategories: string = "";
    getChallenge: string = "";
    getGradesByCountryId:string="";
    getCurrentProfileId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ChallengePagePropsMessage),
            getName(MessageEnum.NavigationChallengeMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationNonPremiumUser)
        ];

        this.state = {
            drawerSide: false, // Provide a default value
            graph: {
                nodes: [],
                edges: [],
            },
            topic: this.props.t("Current Grade"),
            description: "",
            challengeModal: false,
            subCategoryLoading:false,
            challengeShuffleModal: false,
            ToastMessage: '',
            ToastOpen: false,
            course_Node: undefined,
            lessonData: [],
            topicName: '',
            UnitName: '',
            unitId: '',
            ChallengeError: '',
            PremiumUser:false,
            CurrentNode: undefined,
            ChallengeLesson: undefined, 
            gradeCountryId:undefined,
            showCategoryGraph: false,
            avatarIcon:'',
            avatarHealth: 'healthy', 
            avatarColor: '',
            gradeList:{
                nodes:[],
                edges:[]
             },
             storeSubCategory:{},
             searchItem:undefined,
             profileLoading:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
   
    options = {
      autoResize: true,
      physics: {
        enabled: true,
        solver: "forceAtlas2Based",
        stabilization: {
          enabled: true,
          iterations: 1000,
          updateInterval: 25,
        },
        forceAtlas2Based: {
          gravitationalConstant: -50,
          centralGravity: 0.004,
          springLength: 200,
          springConstant: 0.09,
          damping: 0.4,
          avoidOverlap: 1,
        },
        repulsion: {
          nodeDistance: 300,
          centralGravity: 0.1,
          springLength: 300,
          springConstant: 0.05,
          damping: 0.09,
        },
      },
      nodes: {
        borderWidth:0,
        widthConstraint:false,
        shapeProperties: {
          interpolation:false,
          useImageSize: false,
          borderRadius:0,
          useBorderWithImage:true
        },
        font: {
          multi: true,
          size: 14,
           face: 'Quicksand',
          color:'#666',
          bold: {
            color: "#666"
          }
        },
        
      },
  
      layout: {
        randomSeed: undefined,
        improvedLayout: true,
      },
      edges: {
        arrows: {
          to: {
            enabled: false,
            imageHeight: undefined,
            imageWidth: undefined,
            scaleFactor: 1,
            src: undefined,
            type: "arrow"
          },
        },
      
        color: {
          color:'#848484',
          border:'unset',
          highlight:'#848484',
          background:'unset',
          hover: '#848484',
          inherit: 'from',
          opacity:1.0
        },
         length: 200,
      },
      interaction: {
        dragNodes: true,
          zoomView: false,
          dragView: false,
      },
    };
    async componentDidMount() {
        window.scrollTo(0, 0)
        window.addEventListener('resize', this.handleWindowSizeChange);
        this.handleWindowSizeChange();
        this.getCurrentUserProfile();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        if(prevState.gradeCountryId!=this.state.gradeCountryId){
            this.getGradeByCountry()
          }

          if(prevState.searchItem!==this.state.searchItem){
            const categoryId= this.state.searchItem?.data?.attributes.category_id as number
             this.getCategories(categoryId)
          }
    }


    handleChallengeShuffleClose = () => {
        this.getChallengeAPICall()
        setTimeout(() => {
            if (this.state.challengeShuffleModal) {
                this.setState({
                    challengeShuffleModal: false,
                    challengeModal: true
                })
            }
        }, 3500)
    }


    navigateToMain(){
    this.props.navigation.navigate("MainMenuWeb")
    }
    
    NodeFunction:EventHandler = async({ nodes, edges }) => {
      if(nodes){
        const nodeData = JSON.parse(nodes)
        if(nodeData.type == "maingrade") {
            this.setState({
                showCategoryGraph:false,
                searchItem:undefined,
                description:'',
                graph:{
                  nodes:[],
                  edges:[]
                }
              })
          }else{
           if(this.state.storeSubCategory){
            this.handleSearchedGraph(nodeData)
            } 
          }

        }
    }

    handleWindowSizeChange = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 960) {
            this.setState({
                drawerSide: false
            });
        } else {
            this.setState({
                drawerSide: true
            });
        }
    };

    handleChallengeButton=() => {
        if(this.state.PremiumUser){
        this.setState({ challengeShuffleModal: true, challengeModal: false })
        this.handleChallengeShuffleClose()
        }else{
            const addMessage: Message = new Message(getName(MessageEnum.NavigationNonPremiumUser));
            addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(addMessage);
        }
      }

    handleSubCategory = (data: Array<INodeInfo>) => {
      const defaultData={data:[]}
        const result = data.map((item: INodeInfo) => {
          const getChildLength= this.getType(item)
           const image=this.handleDisableImage(getChildLength||defaultData , item.attributes.image  || algebra)
            return {
                id: JSON.stringify(item),
                label: customWrapLabel(item.attributes.name, 15),
                image: image,
                shape: "circularImage",
                type: 'grade',
                size: 25,
            }
        })
        return result
    }

    handleGraphData = async(responseJson: ISubCategoryResponse) => {
        this.setState({
            storeSubCategory:responseJson,
            subCategoryLoading:false
        })
        let newNodes = [
            {
                id:  JSON.stringify({id: responseJson.id||"slkjskdjgskdf", type:'maingrade'}),
                size: 25,
                shape: "circularImage",
                image: responseJson.category_image_url || algebra,
                label: responseJson.grade,
                child: this.getChild(responseJson.child?.data),
            },

        ]
        if(this.state.searchItem===undefined){
        this.setState({
            topic: responseJson.grade,
            CurrentNode: {
                label: responseJson.grade,
                image: responseJson.category_image_url || algebra,
                id: responseJson?.id,
                type: 'category'
            },
        })

        const newEdges =  createEdges(newNodes[0]);
        const nodesNew =  flattenData(newNodes)

        this.setState({
            graph: {
                nodes: nodesNew,
                edges: newEdges
            }
        });
      }else{
        this.handleSearchedGraph(this.state.searchItem.data)
      }
    }


    getChild = (data: Array<INodeInfo>) => {
        if (data?.length > 0 && data[0]?.type === configJSON.subCategoryType) {
            const result = this.handleSubCategory(data)
            return result
        }
    }


    getChallengeAPICall = () => {
        const token = localStorage.getItem('token')
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };
        const getChallengeMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getChallenge = getChallengeMsg.messageId;

        getChallengeMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getChallenge}?type=${this.state.CurrentNode?.type}&id=${this.state.CurrentNode?.id}`
        );
        getChallengeMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getChallengeMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getChallengeMsg.id, getChallengeMsg);
    }

    handleGetChallengeResponse = (response: IChallengeResponse & IError) => {
        if (!response?.errors) {

            if (response.data!=null) {   
                this.setState({
                    ChallengeLesson: response.data.attributes
                })  
            }
             else {
                setTimeout(()=>{
                this.setState({
                    challengeShuffleModal: false,
                    challengeModal: false,
                    ChallengeLesson:undefined,
                    ToastOpen: true,
                    ChallengeError: "lesson not found"
                })
            },1500)
              }      
        } else {
            setTimeout(()=>{
            this.setState({
                // challengeShuffleModal: false,
                challengeModal: false,
                ChallengeLesson: undefined,
                ToastOpen: true,
                ChallengeError: response?.errors[0]
            })
        },2000)
        }
    }
    handleCloseToast = () => {
        this.setState({
            ToastOpen: false,
        })
    }

    getGradeByCountry = async() => {
        this.getGradesByCountryId = sendAPIRequest(`bx_block_categories/categories/get_grades_by_country_id?country_id=${this.state.gradeCountryId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
    }

    handleGrade=async(data:Array<IGrade>)=>{

        const gradeData={
          label:"Grade",
          image:gradeIcon || algebra,
          shape: "circularImage",
          size: 25,
          id:"askdkladgl"
        }
        const gradeNodeList=  data.map((item:IGrade)=>{
         return{
          label:item?.attributes?.name,
          size: 25,
          image:item.attributes.image ?? algebra,
          type:item.type,
          shape: "circularImage",
          id:JSON.stringify(item)
        }
        })
        const subData = [{...gradeData, child: gradeNodeList }]
        this.generateGradeListGraph(subData)
       }

       generateGradeListGraph = async(newNodesData: Array<INodeData>) => {
        const newNodes = newNodesData;
        const newEdges =  createEdges(newNodes[0]);
        const nodesNew =  flattenData(newNodes);
  
        this.setState({
          gradeList: {
            nodes: nodesNew,
            edges: newEdges,
          },
        });
      };
      
      handleChallengeShuffleonClose=() =>{ 
        this.setState({ challengeShuffleModal: false })
      }


      handleBack=() => {
        if(!this.state.showCategoryGraph){
          this.navigateToMain()
        }else{
        this.setState({
          showCategoryGraph:false,
          description:'',
          topicName:this.props.t("Current Grade"),
          graph:{
            nodes:[],
            edges:[]
          }
        })
      }
    }
   
    selectGrade:EventHandler=({ nodes, edges })=>{
      if(nodes){
        const nodedata = JSON.parse(nodes)
        if(nodedata?.type==="grades"){
        this.setState({
            description:nodedata?.attributes?.name,
            showCategoryGraph:true,
           })
        if(this.state.searchItem===undefined){
           this.getCategories(nodedata?.attributes?.id)
        }
       }
      }
      }

      getCategories = async(grade_id:number) => {
        this.setState({
          subCategoryLoading:true
        })
        const token = localStorage.getItem('token')
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };
        const getSubCatagory = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSubCategories = getSubCatagory.messageId;

        getSubCatagory.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.subCategories}?grade_id=${grade_id}`
        );
        getSubCatagory.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getSubCatagory.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getSubCatagory.id, getSubCatagory);
    }


    getCurrentUserProfile = () => {
        this.setState({
            profileLoading:true
         })
        const token = localStorage.getItem('token')
        this.getCurrentProfileId = sendAPIRequest(
          'bx_block_profile/profiles/current_user_profile',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              token,
            },
          }
        )
      }


      handleSubCategoryResponse=(responseJson:ISubCategoryResponse & IError)=>{
        if (!responseJson.errors) {
            this.handleGraphData(responseJson)
        } 
      }


      handleCurrentProfileResponse=(responseJson:ICurrentProfileResponse & IError )=>{
           if(!responseJson.errors){
            this.setState({
                avatarHealth: responseJson?.data?.attributes?.avatar_health,
                avatarColor: responseJson?.data?.attributes?.favorite_color,
                gradeCountryId:responseJson?.data?.attributes?.app_country?.id,
                PremiumUser:responseJson?.data?.attributes?.payment_premium,
                profileLoading:false
               })
              this.updateIconAvatar();
           }
      }


      handleGradeByCountryResponse=(responseJson:IGradeResponse & IError)=>{
        this.handleGrade(responseJson.data)
      }


      handleResponse=(responseJson:ISubCategoryResponse & IGradeResponse & IChallengeResponse & IError & ICurrentProfileResponse , apiRequestCallId:string)=>{
            switch(apiRequestCallId){
                case this.getSubCategories:
                   return this.handleSubCategoryResponse(responseJson)
                case this.getChallenge:
                   return this.handleGetChallengeResponse(responseJson)
                case this.getCurrentProfileId:
                    return this.handleCurrentProfileResponse(responseJson)
                case this.getGradesByCountryId:    
                    return this.handleGradeByCountryResponse(responseJson)
            }
      }


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const countryId =  message.getData(getName(MessageEnum.ChallengePagePropsMessage));
            if(countryId){
            this.setState({
              gradeCountryId:countryId 
            })
            setStorageData("countryId",countryId)
           }
        }
        
        if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
            const searchedGraph = await message.getData(getName(MessageEnum.ChallengeSearchedMessage));
            if(searchedGraph){
             this.setState({
               showCategoryGraph:true,
               searchItem:searchedGraph
             })
            }
          }  
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
            this.handleResponse(responseJson,apiRequestCallId)
        }
    }

    updateIconAvatar() {
        const { avatarColor, avatarHealth } = this.state;
        const colorMap = this.getColorMap();

        const healthIcon = (avatarColor !== undefined && avatarHealth !== undefined) 
    ? ((colorMap as {[key:string]:{[Key:string]:string}})[avatarColor]?.[avatarHealth]) 
    : blueHealthy;

        this.setState({ avatarIcon: healthIcon });
      }

      getColorMap = () => {
        return {
            '#62cdff': { healthy: blueHealthy, happy: blueHappy, weak: blueWeak, worried: blueWorried, ghostly: ghostlyAvatar },
            '#2bce8c': { healthy: greenHealthy, happy: greenHappy, weak: greenWeak, worried: greenWorried , ghostly: ghostlyAvatar},
            '#b7f0ad': { healthy: lightgreenHealthy, happy: lightgreenHappy, weak: lightgreenWeak, worried: lightgreenWorried, ghostly: ghostlyAvatar },
            '#884a39': { healthy: brownHealthy, happy: brownHappy, weak: brownWeak, worried: brownWorried, ghostly: ghostlyAvatar },
            '#f96855': { healthy: redHealthy, happy: redHappy, weak: redWeak, worried: redWorried, ghostly: ghostlyAvatar },
            '#ff8400': { healthy: orangeHealthy, happy: orangeHappy, weak: orangeWeak, worried: orangeWorried, ghostly: ghostlyAvatar },
            '#fdce36': { healthy: yellowHealthy, happy: yellowHappy, weak: yellowWeak, worried: yellowWorried, ghostly: ghostlyAvatar },
            '#a95edb': { healthy: purpleHealthy, happy: purpleHappy, weak: purpleWeak, worried: purpleWorried, ghostly: ghostlyAvatar },
        };
    };
    
    generateNewNodesAndEdges = (newNodesData: Array<INodeData>) => {
        const newNodes = newNodesData;
        const newEdges = createEdges(newNodes[0]);
        const nodesNew = flattenData(newNodes);
    
        this.setState(({
          graph: {
            edges: newEdges,
            nodes: nodesNew,
          },
        }));
      };
    
      getType=(nodeType:INodeInfo)=>{
        switch(nodeType.type){
           
          case configJSON.subCategoryType:  
          return nodeType.attributes.courses
    
          case configJSON.main_course:
            return nodeType.attributes.unit

          case configJSON.course:  
          return nodeType.attributes.main

          case configJSON.unit:  
            return nodeType.attributes.lesson  
        }
      }


      handleDisableImage=(data:IChildData,image:string)=>{
        return data.data.length > 0 ? image : greyImage;
      }
    
      handleCustomdata=(nodeData:INodeInfo)=>{
        const defaultData = {data:[]}
        const getType= this.getType(nodeData)
        const name = nodeData.attributes.course_name || nodeData.attributes.name
       return { 
        id:JSON.stringify(nodeData),
        label: customWrapLabel(name,15),
        shape: "circularImage",
        image:  this.handleDisableImage(getType||defaultData, nodeData.attributes.image || algebra),
        size: 25,
        }
      }
    
      getMapping=(nodeArray:IChildData,)=>{
        const defaultData = {data:[]}
        const result = nodeArray.data.map((item: INodeInfo,index:number) => {
          const name = item.attributes.name || item.attributes.course_name
          const getChildLength = this.getType(item) 
          return {
            image: this.handleDisableImage(getChildLength||defaultData , item.attributes.image  || algebra),
            label: name && customWrapLabel(name, 15),
            shape: "circularImage",
            id: JSON.stringify({...item,index:index+1}),
            type: 'grade',
            size: 25,
            fixed:false
          }
        })
        return result
      }
    
       
       handleCourseTypeGraphData=(searchedGraph:INodeInfo)=>{
        const subCategory = searchedGraph.attributes.sub_category_id
        const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
        const subCatNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
        const courseNode = subCatNode.attributes.courses?.data.find((courseItem:INodeInfo)=>courseItem.id==searchedGraph.id) as INodeInfo
    
        const courseNodeData= this.handleCustomdata(courseNode)
        const courseChildNodeArray={...courseNodeData,child:this.getMapping(courseNode.attributes.main as IChildData)}
    
        const subCatNodeData = this.handleCustomdata(subCatNode)
        const subCategoryChildNode=[ { ...subCatNodeData ,child:[{...courseChildNodeArray}]} ]
        this.setState({
          topic : subCatNode.attributes.name,
          description:searchedGraph.attributes.course_name??''
        })
        this.generateNewNodesAndEdges(subCategoryChildNode)
      }


      handleLessonTypeGraphData=(searchedGraph:INodeInfo)=>{
        const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
        const subCategory = searchedGraph.attributes.sub_category_id
        const main = searchedGraph.attributes.main_id
        const course = searchedGraph.attributes.course_id 
        const unit = searchedGraph.attributes.unit_id
        const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
        const courseNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>parseInt(courseItem.id)==course) as INodeInfo
        const mainNode = courseNode.attributes.main?.data?.find((mainItem:INodeInfo)=>parseInt(mainItem.id)==main)as INodeInfo
        const unitNode = mainNode.attributes.unit?.data?.find((unitItem:INodeInfo)=>parseInt(unitItem.id)==unit)as INodeInfo
    
        const LessonData = unitNode.attributes.lesson?.data?.map((item: INodeInfo) => {
          const name = item.attributes.name 
          return {
            id: JSON.stringify(item),
            shape: "circularImage",
            type: 'grade',
            size: 25,
            image: item.attributes.image  || algebra,
            label: name && customWrapLabel(name, 15),
            fixed:false
          }
        })

          setStorageData("challengeLesson", JSON.stringify({
                UnitName: unitNode.attributes.name,
                isPremium:this.state.PremiumUser,
                lessonData: LessonData,
                topicName: courseNode.attributes.course_name as string,
                unitId: unitNode.id
            })
        )
        this.props.navigation.navigate('ChallengeLessons')
      }

      handleTypeSubCategory=(searchedGraph:INodeInfo)=>{
        const subCategory = searchedGraph.attributes.id
        const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
        const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) 
        if(subCategoryNode){
        const subCatNodeData= this.handleCustomdata(subCategoryNode)
         const subCategoryChildNodeData = {...subCatNodeData,child:this.getMapping(subCategoryNode.attributes.courses as IChildData)}
         const childata =this.getChild(storeSubCategory.child.data)
         const GradeChildArray =childata?.map((item:INode)=>{
          if(JSON.parse(item.id).id==subCategory){
            return subCategoryChildNodeData
          }else{
          return  item
          }
          
        })
        const GradeNode = [{
          id: JSON.stringify({id: "ddsgdg", type:'maingrade'}),
          image: storeSubCategory.category_image_url || algebra,
          size: 25,
          child: GradeChildArray,
          label: storeSubCategory.grade,
          shape: "circularImage",
          }] as INodeData[]
         
          this.setState({
          description:searchedGraph.attributes.name,
          topic:storeSubCategory.grade,
         })
          this.generateNewNodesAndEdges(GradeNode)
        }
         
       } 
    
      handleUnitTypeGraphData=(searchedGraph:INodeInfo)=>{
        const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
        const subCategory = searchedGraph.attributes.sub_category_id
        const course = searchedGraph.attributes.course_id 
        const main = searchedGraph.attributes.main_id

        const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
        const courseObjNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>parseInt(courseItem.id)==course) as INodeInfo
        const mainNode = courseObjNode.attributes.main?.data?.find((mainItem:INodeInfo)=>parseInt(mainItem.id)==main)as INodeInfo
        const unitObjNode = mainNode.attributes.unit?.data?.find((unitItem:INodeInfo)=>unitItem.id==searchedGraph.id)as INodeInfo
    
        const LessonDataArray = unitObjNode.attributes.lesson?.data?.map((item: INodeInfo) => {
          const name = item.attributes.name 
          return {
            id: JSON.stringify(item),
            image: item.attributes.image  || algebra,
            type: 'grade',
            size: 25,
            label: name && customWrapLabel(name, 15),
            shape: "circularImage",
            fixed:false
          }
        })

        this.setState({
          UnitName:unitObjNode.attributes.name,
          lessonData:LessonDataArray as Array<INodeData>,
          topic: mainNode.attributes.name, 
          topicName:courseObjNode.attributes.course_name as string,
          description:searchedGraph.attributes.name
        })
    
      const unitObjNodeData = this.handleCustomdata(unitObjNode)
      const unitChildNode={...unitObjNodeData, child: LessonDataArray
      }
      const mainNodeDataObj = this.handleCustomdata(mainNode)
      const mainChildNode={...mainNodeDataObj, child:[{...unitChildNode}]}

      const courseObjNodeData= this.handleCustomdata(courseObjNode)
      const courseChildNode={...courseObjNodeData,child:[{...mainChildNode}]}
    
      const subCategoryNodeData = this.handleCustomdata(subCategoryNode)
      const subCategoryChildNode=[{ ...subCategoryNodeData ,child:[{...courseChildNode}]}]
    
      this.generateNewNodesAndEdges(subCategoryChildNode)
         
      }



      handleSearchedGraph=(searchedGraph:INodeInfo)=>{
        this.setState({
            CurrentNode: {
                id: searchedGraph.id,
                label: searchedGraph.attributes.name || searchedGraph.attributes.course_name as string,
                image: searchedGraph.attributes.image || algebra,
                type: searchedGraph.type,
            }
        })
        switch(searchedGraph.type){
    
          case configJSON.subCategoryType:
            return this.handleTypeSubCategory(searchedGraph)

          case configJSON.main_course:
          return this.handleMainTypeGraphData(searchedGraph)  
           
          case configJSON.course:
            return this.handleCourseTypeGraphData(searchedGraph)
  
          case configJSON.unit:
            return this.handleUnitTypeGraphData(searchedGraph)  
        
          case configJSON.lesson:
            return this.handleLessonTypeGraphData(searchedGraph)    
        }
    }

      handleMainTypeGraphData=(searchedGraph:INodeInfo)=>{
        const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
        const subCategory = searchedGraph.attributes.sub_category_id
        const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
        const course = searchedGraph.attributes.course_id  
        const courseNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>parseInt(courseItem.id)==course)as INodeInfo
        const mainNode = courseNode.attributes.main?.data.find((mainItem:INodeInfo)=>mainItem.id==searchedGraph.id)as INodeInfo
    
        const mainNodeDataObj = this.handleCustomdata(mainNode)
        const mainChildNodeArray={...mainNodeDataObj, child: this.getMapping(mainNode.attributes.unit as IChildData)}

        const courseNodeData= this.handleCustomdata(courseNode)
        const childCourseNode={...courseNodeData,child:[{...mainChildNodeArray}]}
      
        const subCategoryNodeData = this.handleCustomdata(subCategoryNode)
        const subCategoryChildNode=[{ ...subCategoryNodeData ,child:[{...childCourseNode}]}]
    
        this.setState({
          description:searchedGraph.attributes.name,
          topic: courseNode.attributes.course_name as string, 
        })

      this.generateNewNodesAndEdges(subCategoryChildNode)
    
      }
    
    // Customizable Area End

}

// Customizable Area Start
// Customizable Area End