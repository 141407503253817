import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { contactusBlue, contactusBrown, contactusGreen, contactusLightgreen, contactusOrange, contactusPurple, contactusRed, contactusYellow, orangebearmsg, bluebearmsg, mintbearmsg, purplebearmsg, redbearmsg, brownbearmsg, yellowbearmsg, greenbearmsg } from "./assets";
import { FormikErrors } from "formik";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean
  token: string
  showModalSuccess: boolean
  avatarColor:any
  contactUsAvatar:any
  msgTeddy:any
  contactData: {
    email: string,
    name: string,
    description: string,
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sendContactUsId: string = ''
  getCurrentProfileId: string = ''

  contactData = {
    email: '',
    name: '',
    description: '',
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isLoading: false,
      token: '',
      showModalSuccess: false, 
      avatarColor: '',
      contactUsAvatar:'',
      msgTeddy:'',
      contactData : this.contactData,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({ token: localStorage.getItem('token') || ''}, this.getCurrentUserProfile)
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.sendContactUsId
    ) {
      if (responseJson?.errors) {
        this.setState({
          isLoading: false,
        })
        return
      }
      this.setState({
        isLoading: false,
        showModalSuccess: true,
        contactData : this.contactData,
      })
    }

    if (apiRequestCallId === this.getCurrentProfileId && responseJson) {
      this.setState({
        avatarColor: responseJson?.data?.attributes?.favorite_color,
       })
       this.contactUsAvatar();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  submitContactUs = (values : {email: string, name: string, description: string}) => {
    const {email, name, description} = values;
    const body = {
      data: {
        email,
        name,
        description
      }
    }
    this.setState({ isLoading: true })
    this.sendContactUsId = sendAPIRequest(
      'bx_block_contact_us/contacts',
      {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token
        },
      }
    )
  }

  getCurrentUserProfile = () => {
    this.getCurrentProfileId = sendAPIRequest(
      'bx_block_profile/profiles/current_user_profile',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token
        },
      }
    )
  }

  contactUsAvatar = () => {
    const colorToContactTeddy = {
        '#ff8400': contactusOrange,
        '#62cdff': contactusBlue,
        '#b7f0ad': contactusLightgreen,
        '#a95edb': contactusPurple,
        '#f96855': contactusRed,
        '#884a39': contactusBrown,
        '#fdce36': contactusYellow,
        '#2bce8c': contactusGreen
    };

    const colorToUpdateTeddy = {
      '#ff8400': orangebearmsg,
      '#62cdff': bluebearmsg,
      '#b7f0ad': mintbearmsg,
      '#a95edb': purplebearmsg,
      '#f96855': redbearmsg,
      '#884a39': brownbearmsg,
      '#fdce36': yellowbearmsg,
      '#2bce8c': greenbearmsg
  }
    const contactTeddy = (colorToContactTeddy as any)[this.state.avatarColor] || contactusBlue ;
    const updateTeddy = (colorToUpdateTeddy as any)[this.state.avatarColor] || bluebearmsg;
        this.setState({ contactUsAvatar: contactTeddy, msgTeddy: updateTeddy });
  }

  checkTouchedAndErrorInput = (touched: any, errors: any) => {
    return Boolean(touched && errors)
  }

  getErrors=(errors:FormikErrors<{name:string,email:string,description:string}>)=>{
    return  errors.email&&errors.name&&errors.description?'':errors.name||errors.email||errors.description
    }
  // Customizable Area End
}
