import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  List,
  ListSubheader,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LanguageOptionsController, {
  Props,
} from "./LanguageOptionsController.web";
import NavigateLayout from "../../../components/src/NavigateLayout";
import SettingBackground from "../../settings2/src/SettingBackground";
import SettingHeader from "../../settings2/src/SettingHeader";
import { imgCheckedCheckbox, imgLanguageHeader, imgUncheckCheckbox } from "./assets";
import { imgEnglishFlag, imgGermanFlag, imgHungarianFlag, imgRomanianFlag } from "../../../components/src/assets";
import { withTranslation } from "react-i18next";

// Customizable Area End

class LanguageOptions extends LanguageOptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  languageOptions = {
    English: imgEnglishFlag,
    German: imgGermanFlag,
    Hungarian: imgHungarianFlag,
    Romanian: imgRomanianFlag
  }

  handleClickGoBack = () => {
    this.props.navigation?.goBack()
  }

  handleClickSubmitBtn = () => {
    this.changeAppLanguage()
  }

  checkIcon = (id: number) => {
    return id === this.state.selectedLanguageId? imgCheckedCheckbox : imgUncheckCheckbox
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Settings">
        <LanguageOptionsContainer>
          <SettingBackground />
          <Box className="contentContainer" data-test-id="container" id="container">
            <SettingHeader
              data-testId='settingHeader'
              onGoBack={this.handleClickGoBack}
              title={this.props.t("Language")}
              headerImage={imgLanguageHeader}
              disableButton={this.state.selectedLanguageId ===  null}
              onClickButton={this.handleClickSubmitBtn}
              actionButtonText={this.state.saveButtonText}
              isLanguage
            />
            <Box className="languageOptionsContainer">
              <Box className="languageOptionsWrapper">
                {this.state.isLoading ? (
                  <CircularProgress style={{color: '#A95EDB'}} />
                ) : (
                  <>
                    <List
                    component="nav"
                    className="accountWrapper"
                    subheader={
                      <ListSubheader disableSticky component="div" className="languageListTitle">
                        {this.props.t("Current Language")}
                      </ListSubheader>
                    }
                    >
                      {this.state.currentLanguage ? (
                        <Box className="languageItemWrapper">
                          <Box className="languageItem">
                            <img src={this.languageOptions[this.state.currentLanguage.attributes.name]} width={23} height={18} />
                            <Typography className="languageItemText">
                              {this.state.currentLanguage.attributes.name}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Typography className="noLanguageSelectText">{this.props.t("No language selected!")}</Typography>
                      )}
                    </List>
                    <List
                      component="nav"
                      className="accountWrapper marginNav"
                      subheader={
                        <ListSubheader disableSticky component="div" className="languageListTitle">
                          {this.props.t("Change Language")}
                        </ListSubheader>
                      }
                    >
                      {this.state.languageOptionsList?.map((item) => (
                          <Box
                            data-testId='languageItem'
                            onClick={() => this.setState({selectedLanguageId: item.attributes.id,selectLanguage:item.attributes.name })}
                            key={item.id}
                            className="languageItemWrapper"
                          >
                            <Box className="languageItem">
                              <img src={this.languageOptions[item.attributes.name]} width={23} height={18} />
                              <Typography className="languageItemText">
                                {item.attributes.name}
                              </Typography>
                            </Box>
                            <img  
                              src={this.checkIcon(item.attributes.id)} 
                              width={18} 
                              height={18}
                            />
                          </Box>
                        )
                      )}
                    </List>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </LanguageOptionsContainer>
      </NavigateLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(LanguageOptions)
const LanguageOptionsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#A95EDB',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  '& .contentContainer': {
    flex: '6',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .languageOptionsContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#A95EDB',
    overflow: 'scroll',
    width: '100%',
    scrollbarWidth: 'none'
  },

  '& .languageOptionsContainer::-webkit-scrollbar': {
    display: 'none'
  },

  '& .languageOptionsWrapper': {
    overflow: 'scroll',
    flex: 1,
    display: 'flex',
    backgroundColor: '#fff',
    paddingTop: '30px',
    flexDirection: 'column',
    padding: '0 24px',
    alignItems: 'center',
    scrollbarWidth: 'none'
  },

  '& .languageOptionsWrapper::-webkit-scrollbar': {
    display: 'none'
  },

  '& .accountWrapper': {
    width: '100%',
    maxWidth: '450px',
    border: '2px solid #e6e6e6',
    borderRadius: '12px',
  },

  '& .languageListTitle': {
    height: '39px',
    display: 'flex',
    textTransform:'uppercase',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#999999',
    fontSize: '12px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    backgroundColor: '#e6e6e6',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },

  '& .marginNav': {
    marginTop: '30px',
    marginBottom: '84px',
  },

  '& .languageItemWrapper': {
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    backgroundColor: '#f7f7f7', 
    margin: '22px', 
    padding: '11px 16px',
    borderRadius: '10px',
    border: '2px solid #E6E6E6',
  },

  '& .languageItem': {
    display: 'flex',
    alignItems: 'center'
  },

  '& .languageItemText': {
    marginLeft: '8px',
    fontFamily: 'Quicksand',
    fontWeight: '500',
    fontSize: '16px',
    color: '#363636'
  },

  '& .noLanguageSelectText': {
    marginLeft: '22px',
    fontFamily: 'Quicksand',
    fontWeight: '500',
    marginTop: '10px',
    fontSize: '16px',
    color: '#363636'
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',

    '& .languageOptionsWrapper': {
      borderRadius: '24px 0 0 0',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px',
  },
}))
// Customizable Area End
