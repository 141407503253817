import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {imgGoldTrophy} from './assets'

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  subscription = [
    {
      label: 'Current Plan',
      title: 'Freemium',
      desc: 'Basic Plan with limited benefits',
      experience: '',
      values: [
        'Access to all lessons',
        'Access to reports, progress and status',
        'Access to learning materials*',
        'Limited usage (10 lessons / day)',
        'Limited access to competitions with prizes'
      ]
    },
    {
      label: 'Upgrade to',
      title: 'Premium',
      desc: 'Become VIP member to access the app at fullest',
      experience: '1000XP/mo',
      values: [
        'Access to all lessons',
        'Access to reports, progress and status',
        'Unconditioned access to learning materials',
        'Unlimited usage',
        'Unlimited access to competitions with prizes',
        'Learning recommendation',
        'Access to live lessons'
      ]
    }
  ]
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <SubscriptionContainer>
            <h1 className="subscriptionTitle">Subscription</h1>
            {this.subscription.map(sub => (
              <div key={sub.title}>
                <h2 className="subscriptionItemTitle">{sub.label}</h2>
                <div className="subscriptionItemWrapper">
                  <div className="subscriptionItemHeader">
                    <img src={imgGoldTrophy} width={60} height={68} />
                    <div className="subscriptionItemHeaderWrapper">
                      <h3 className="subscriptionItemHeaderTitle">{sub.title}</h3>
                      <p className="subscriptionItemHeaderDesc">{sub.desc}</p>
                    </div>
                  </div>
                  {sub.experience && <p className="subscriptionItemExp">{sub.experience}</p>}
                  {sub.values.map(value => (
                    <p key={value} className="subscriptionItemValue">&#x2022; {value}</p>
                  ))}
                </div>
              </div>
            ))}
            <div className="getPremiumBtnWrapper" data-test-id="getPremiumBtnWrapper">
              <button className="getPremiumBtn" data-test-id="getPremiumBtn">Can’t Wait? Get Premium Now!</button>
            </div>
          </SubscriptionContainer>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SubscriptionContainer = styled("div")({
  marginTop: "64px",
  padding: "0 24px",

  "& p, h1, h2, h3": {
    margin: 0
  },

  "& .subscriptionTitle": {
    fontWeight: "700",
    marginBottom: "24px",
    textAlign: "center",
    fontSize: "32px",
  },

  "& .subscriptionItemTitle": {
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: "24px",
  },

  "& .subscriptionItemWrapper": {
    padding: "24px",
    backgroundColor: '#F4EEEE',
    borderRadius: '10px',
    marginBottom: '64px'
  },

  "& .subscriptionItemHeader": {
    display: "flex",
    alignItems:'center',
    marginBottom: '16px',
  },

  "& .subscriptionItemHeaderWrapper": {
    marginLeft: "16px",
  },

  "& .subscriptionItemHeaderTitle": {
    fontSize: "24px",
    fontWeight: '700',
    marginBottom: '16px'
  },

  "& .subscriptionItemHeaderDesc": {
    fontSize: "12px",
  },

  "& .subscriptionItemExp": {
    fontSize: "36px",
    fontWeight:'700',
    marginBottom: '24px',
    textAlign: 'center',
  },

  "& .subscriptionItemValue": {
    fontSize: "14px",
    marginBottom: '12px',
  },

  "& .getPremiumBtnWrapper": {
    display: "flex",
    justifyContent: "center",
  },

  "& .getPremiumBtn": {
    height: "48px",
    width: "260px",
    borderRadius: "8px",
    backgroundColor: "#d9d9d9",
    color: "#000",
    marginBottom: "64px",
    fontSize: "14px",
    borderWidth: "0",
    boxShadow: "2px 5px 2px rgba(0, 0, 0, 0.3)",
  },
})
// Customizable Area End
